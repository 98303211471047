import React from "react";
import SEO from "../components/seo";
import { Heading } from "theme-ui";
import PrimaryLayout from "../components/layout/primary/primary";
import ContentContainer from "../components/container/content-container";
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";
import { linkResolver } from "../utils/linkResolver";
import Post from "../templates/blog-template";
import Product from "../templates/product-page";
import Shop from "../pages/shop";

const NotFoundPage: React.FC<{}> = () => (
  <PrimaryLayout>
    <SEO title="404: Not found" />
    <ContentContainer width="100%">
      <Heading
        as="h2"
        sx={{
          color: "#054752",
          fontSize: "30px",
          paddingTop: "2rem",
          fontWeight: 400,
        }}
      >
        Opps! Page not found
      </Heading>
    </ContentContainer>
  </PrimaryLayout>
);

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME || "",
    linkResolver,
    componentResolver: componentResolverFromMap({
      blog_content_model: Post,
      product: Product,
      landing_page: Shop,
    }),
  },
]);
